/*eslint-disable*/
import React from "react";

export default {
  en: {
    quote: "A solution that works very very well",
    author: "David Thoumas, Co-founder and Technical Director",
    sector: "Software editor",
    interviewees: ["David Thoumas, Co-founder and Technical Director"],
    service: "Dynamic Maps",
    geo: "World",
    blocks: [
      {
        title: "OpenDataSoft",
        description: (
          <span>
            <a
              href="https://www.opendatasoft.com/"
              style={{ textDecoration: "underline" }}
              rel="noopener noreferrer"
              target="_blank"
            >
              Opendatasoft
            </a>{" "}
            is a leader SaaS platform in the field of data management &amp;
            sharing, made in France. Their mission is to help organizations
            value the information they are producing, allowing them to address
            practical business problems, without requiring technical expertise.
            David Thoumas, as co-founder and technical director, is responsible
            for the development team, and provides support to many clients on
            specific projects.
          </span>
        ),
        img: "clientLogo",
      },
      {
        title: "OpenDataSoft & Jawg",
        description: (
          <span>
            Among the great amount of data passing through the Opendatasoft
            platform, much is geospatial. For David,{" "}
            <q>
              There is nothing better than a map to share geolocated information
            </q>
            . Opendatasoft needed the ability to distribute and share data on
            maps from day one.
          </span>
        ),
        img: "usageHighlight",
      },
      {
        title: "",
        description: (
          <span>
            All default styles of Jawg maps are available by default to all of
            their customers who use it on public portals. One of the things that
            David likes the most about the solution is to have a set of basemaps
            constantly maintained :{" "}
            <q>It's always a nice surprise to see great new styles appear</q>.
            This allows them to offer richer map contexts to their customers.
          </span>
        ),
      },
      {
        title: "",
        description: (
          <span>
            For David, the integration of Jawg's services was easy:{" "}
            <q>The interface between our two products is relatively simple</q>.
            After a short adaptation phase, the maps are up and running for the
            hundreds of Opendatasoft live portals.
          </span>
        ),
      },
      {
        title: "Jawg Maps in 3 words?",
        description: "Map! Friendly! French!",
      },
    ],
  },
  fr: {
    quote: "Une solution qui fonctionne très très bien",
    author: "David Thoumas, Co-fondateur et Directeur Technique",
    sector: "Éditeur de logiciel",
    interviewees: ["David Thoumas, Co-fondateur et Directeur Technique"],
    service: "Dynamic Maps",
    geo: "Monde",
    blocks: [
      {
        title: "OpenDataSoft",
        description: (
          <span>
            <a
              href="https://www.opendatasoft.com/fr/"
              style={{ textDecoration: "underline" }}
              rel="noopener noreferrer"
              target="_blank"
            >
              Opendatasoft
            </a>{" "}
            , c’est la plateforme SaaS de partage de données made in France.
            Leur mission est de permettre, à des gens qui ne sont pas des
            experts de la technologie, d’exploiter et de valoriser l’information
            produite par leur organisation, pour répondre à des problèmes
            métiers concrets. En tant que fondateur et directeur technique,
            David Thoumas a pour mission d’accompagner les équipes qui
            travaillent sur le développement du produit, et d’accompagner les
            clients sur des projets spécifiques.
          </span>
        ),
        img: "clientLogo",
      },
      {
        title: "OpenDataSoft & Jawg",
        description: (
          <span>
            Parmis les nombreuses données qui passent par la plateforme
            d’Opendatasoft, une bonne partie est une information de nature
            géographique. Pour David,{" "}
            <q>
              &#160;Il n’y a rien de mieux qu’une carte pour partager de
              l’information géographique&#160;
            </q>
            . C’est un besoin d’Opendatasoft depuis le premier jour que de
            pouvoir diffuser et partager des données sur des cartes.
          </span>
        ),
        img: "usageHighlight",
      },
      {
        title: "",
        description: (
          <span>
            Les fonds de carte Jawg sont proposés par défaut pour tous leurs
            clients, qui exposent leurs données sur des portails de partage. Un
            des éléments qui plaît le plus à David dans la solution, c’est
            d’avoir un ensemble de fonds de carte constamment entretenus :
            <q>
              &#160;C’est toujours une belle surprise de voir les nouveaux fonds
              de carte apparaître&#160;
            </q>
            . Cela leur permet d’enrichir les cartes qu’ils proposent à leurs
            clients.
          </span>
        ),
      },
      {
        title: "",
        description: (
          <span>
            Pour David, l’intégration des services de Jawg s’est faite
            facilement :{" "}
            <q>
              &#160;L’interface entre nos deux produits est relativement
              simple&#160;
            </q>
            . Après une courte phase d’adaptation, les cartes sont en place et
            fonctionnent pour les centaines de portails live d’Opendatasoft.
          </span>
        ),
      },
      {
        title: "Jawg Maps en 3 mots ?",
        description: "Carte ! Sympa ! Français !",
      },
    ],
  },
};
